import React from "react";
import { WithAuth } from "../../components/molecules/Auth/WithAuth";
import { BannedClientsPage } from "../../components/pages/AdminPage/BannedClientsPage/BannedClientsPage";

const Page: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <WithAuth adminOnly={true}>
            <BannedClientsPage />
        </WithAuth>
    );
};

export default Page;
