import { Button, Table } from "antd";
import * as React from "react";
import { useBannedClientsList } from "../../../../hooks/api/admin/bannedClients/useBannedClientsList";
import { useDeleteBannedClient } from "../../../../hooks/api/admin/bannedClients/useDeleteBannedClient";
import { usePostBannedClient } from "../../../../hooks/api/admin/bannedClients/usePostBannedClient";
import { PrimaryButton } from "../../../atoms/Button/PrimaryButton";
import { Spinner } from "../../../atoms/Spinner/Spinner";
import { TextInput } from "../../../atoms/TextInput/TextInput";
import { AdminNavigation } from "../AdminNavigation";

const clientIdLength = 36;

interface BannedClient {
    readonly _id: string;
    readonly reason: string;
    readonly clientId: string;
    readonly createdAt: string;
}

export const BannedClientsPage: React.FC = () => {
    const [clientId, setClientId] = React.useState<string>("");
    const [reason, setReason] = React.useState<string>("");

    const bannedClientsList = useBannedClientsList();
    const { mutation: postBannedClient } = usePostBannedClient(clientId, reason, () => {});
    const onClientIdChanged = React.useCallback(
        (_, value: string) => {
            setClientId(value);
        },
        [setClientId]
    );

    const onReasonChanged = React.useCallback(
        (_, value: string) => {
            setReason(value);
        },
        [setReason]
    );
    const onBlockClient = React.useCallback(() => {
        postBannedClient.mutate();
    }, [postBannedClient]);

    const { mutation: deleteBannedClient } = useDeleteBannedClient();

    const isBlockButtonDisabled = clientId.length !== clientIdLength;

    const columns = [
        {
            title: "Id",
            dataIndex: "_id",
            key: "_id",
        },
        {
            title: "ClientId",
            dataIndex: "clientId",
            key: "clientId",
        },
        {
            title: "Reason",
            dataIndex: "reason",
            key: "reason",
        },
        {
            title: "Actions",
            key: "actions",
            render: (_text: string, bannedClient: BannedClient) => (
                <Button
                    type="primary"
                    onClick={() => {
                        deleteBannedClient.mutate(bannedClient.clientId);
                    }}
                >
                    Delete
                </Button>
            ),
        },
    ];

    const dataSource: BannedClient[] = bannedClientsList.data?.bannedClients ?? [];

    if (bannedClientsList.isLoading || deleteBannedClient.isLoading || postBannedClient.isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <AdminNavigation />
            <TextInput label="Client id to block" placeholder="clientId" value={clientId} onChange={onClientIdChanged} />
            <TextInput label="Reason" placeholder="reason" value={reason} onChange={onReasonChanged} />
            <PrimaryButton disabled={isBlockButtonDisabled} onClick={onBlockClient}>
                Block
            </PrimaryButton>
            <Table columns={columns} dataSource={dataSource} rowKey="_id" />
        </>
    );
};
