import { useQuery } from "@tanstack/react-query";
import axios, { AxiosRequestConfig } from "axios";
import { getAxiosRequestConfig } from "../../../../functions/getAxiosRequestConfig";
import { Constants } from "../../../../services/Constants/Constants";
import { useAuth } from "../../../useAuth";
import { QueryCacheKey } from "../../QueryCacheKey";

interface IResponse {
    readonly bannedClients: Array<{
        readonly _id: string;
        readonly clientId: string;
        readonly createdAt: string;
        readonly reason: string;
    }>;
}

const getBannedClients = async (config: AxiosRequestConfig) => {
    const uri = `${Constants.ApiRoot}/admin/banned-client/list`;
    const { data } = await axios.get<IResponse>(uri, config);
    return data;
};

export const useBannedClientsList = () => {
    const { authToken } = useAuth();
    const config = getAxiosRequestConfig(authToken);
    return useQuery([QueryCacheKey.BannedClients], () => getBannedClients(config));
};
